import { ReactElement } from 'react';
import WarrantySVG from 'assets/warranty.svg';
import ShippingSVG from 'assets/shipping.svg';
import BillingSVG from 'assets/billing.svg';
import ReviewSVG from 'assets/review.svg';
import ConfirmationSVG from 'assets/confirmation.svg';
import { useAppNavigation } from 'utils/hooks';
import { getSavedCheckoutData } from 'utils/helpers/checkout';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentQuoteState } from 'state/atoms';
import { Stepper, StepperStep } from '@Calix-Commerce/design-system/components';

export const CheckoutStepper = ({
  currentStep,
  validate,
}: {
  currentStep: number;
  validate?: (navigationRedirectFn?) => Promise<void>;
}): ReactElement => {
  const { transactionId } = useParams();
  const {
    redirectToCheckoutWarranty,
    redirectToCheckoutBilling,
    redirectToCheckoutShipping,
    redirectToCheckoutReview,
  } = useAppNavigation();
  const { shippingAddress, billingAddress } = getSavedCheckoutData(transactionId);
  const { items, requiresWarrantyConfiguration } = useRecoilValue(currentQuoteState);
  const disableWarrantyStep = items.every((item) => !item.extendedWarrantyEligible);
  const steps: StepperStep[] = [
    {
      icon: <WarrantySVG />,
      name: 'Warranty',
      onClickHandler: () => {
        redirectToCheckoutWarranty();
      },
      onHoverText: disableWarrantyStep ? 'No warranty eligible items included' : '',
      disabled: disableWarrantyStep,
    },
    {
      icon: <ShippingSVG />,
      name: 'Shipping',
      onClickHandler: () => {
        redirectToCheckoutShipping();
      },
      disabled: requiresWarrantyConfiguration,
    },
    {
      icon: <BillingSVG />,
      name: 'Billing',
      onClickHandler: () => {
        redirectToCheckoutBilling();
      },
      disabled: !shippingAddress || requiresWarrantyConfiguration,
    },
    {
      icon: <ReviewSVG />,
      name: 'Order Review',
      onClickHandler: () => {
        redirectToCheckoutReview();
      },
      disabled: !billingAddress || requiresWarrantyConfiguration,
    },
    {
      icon: <ConfirmationSVG />,
      name: 'Confirmation',
      disabled: true,
    },
  ];

  return <Stepper steps={steps} currentStepIndex={currentStep} validate={validate}></Stepper>;
};
